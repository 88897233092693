/* Ensure that the entire body is a flex container */
body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ensure that body is at least the height of the viewport */
    background-color: #f0f0f0;
  }

  #root {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100vw;
  }
  
  /* Styling for the image */
  img {
    max-width: 90%; /* Limit the width to 90% of its container */
    max-height: 80vh; /* Limit the height to 80% of the viewport */
    object-fit: contain; /* Preserve aspect ratio */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Drop shadow */
  }
  

  